import React, { useState } from 'react';
import { css } from '@emotion/react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';
import { Row, Grid, Col } from '@sumup/circuit-ui/legacy';
import PropTypes from 'prop-types';

import MultiFeatureShowcaseImage from './components/MultiFeatureShowcaseImage';

import {
  imagePropType,
  richTextPropType,
} from '~/shared/util/shared-prop-types';
import dataSelector from '~/shared/util/data-selector';
import ExpansionPanelList from '~/shared/components/ExpansionPanelList';
import Image from '~/shared/components/Image';
import Link from '~/shared/components/Link';
import SectionHeader from '~/shared/components/SectionHeader';
import useIsMobile from '~/shared/hooks/use-is-mobile';
import { ALIGNMENT } from '~/shared/constants';
// eslint-disable-next-line max-len
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const DATA_SELECTOR = 'multi_feature_showcase';

const panelImageStyles = ({ theme }) => css`
  display: none;
  margin-bottom: ${theme.spacings.mega};
  border-radius: ${theme.spacings.bit};

  ${theme.mq.untilKilo} {
    display: block;
  }
`;

const PanelImage = styled(Image)(panelImageStyles);

const ctaStyled = ({ theme }) => css`
  margin: ${theme.spacings.kilo};
  margin-top: ${theme.spacings.giga};
  display: inline-block;
`;

const expansionPanelListStyles = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const ExpansionPanelListStyled = styled(ExpansionPanelList)(
  expansionPanelListStyles,
);

const Cta = styled(Button)(ctaStyled);

const mapExpansionPanel = (item) => {
  const image = item.imageMobile || {};

  return {
    trackingId: item.trackingId,
    optimizelyFullStackClickEvents: item.optimizelyFullStackClickEvents,
    headline: item.featureName,
    content: item.description,
    headingElement: (
      <PanelImage
        data-selector={dataSelector('image_panel', DATA_SELECTOR)}
        src={image.url}
        sizes="(min-width: 300px) 40vw, 100vw"
        srcSet={[
          { src: image.url, size: '400w', height: 400, width: 400 },
          { src: image.url, size: '800w', height: 800, width: 800 },
        ]}
        {...image}
      />
    ),
    ...item,
  };
};

const sectionHeadingStyles = ({ theme }) => css`
  padding: 0 ${theme.spacings.kilo};
`;

const StyledSectionHeading = styled(SectionHeader)(sectionHeadingStyles);

const MultiFeatureShowcase = ({
  headline,
  content = {},
  buttonLabel,
  buttonUrl,
  buttonTrackingId,
  buttonOptimizelyFullStackClickEvents,
  contentType,
  name,
  id,
  items: originalItems = [],
  fallbackImage = {},
  alignment = ALIGNMENT.LEFT,
  index,
}) => {
  const { experiments } = useOptimizelyData();
  const items = mapVariations(originalItems, experiments);
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const isMobile = useIsMobile();
  const imageIsLeftAligned = alignment === ALIGNMENT.LEFT;

  if (isEmpty(items)) {
    return null;
  }

  const showcaseImage = get('image', selectedItem) || fallbackImage;

  const onOpenPanel = (item) => {
    setSelectedItem(item);
  };

  const onClosePanel = () => {
    setSelectedItem(null);
  };

  const imageCol = (
    <Col span={{ default: 12, kilo: 6 }}>
      <MultiFeatureShowcaseImage
        data-selector={dataSelector('image_big', DATA_SELECTOR)}
        src={showcaseImage.url}
        alt={showcaseImage.alt}
      />
    </Col>
  );

  const contentCol = (
    <Col span={{ default: 12, kilo: 6 }}>
      <ExpansionPanelListStyled
        onlyOne={true}
        alwaysOpen={true}
        closingDisabled={true}
        openPanelId={0}
        onOpen={onOpenPanel}
        onClose={onClosePanel}
        headingAlignment={ExpansionPanelList.LEFT}
        headingColorActive={ExpansionPanelList.BLUE}
        expansionPanels={items.map(mapExpansionPanel)}
        index={index}
      />
      {buttonUrl && buttonLabel && (
        <Link
          href={buttonUrl}
          trackingId={buttonTrackingId}
          optimizelyFullStackClickEvents={buttonOptimizelyFullStackClickEvents}
          trackingContentEntry={{
            contentType,
            contentEntryId: id,
            contentEntryName: name,
          }}
        >
          <Cta
            variant="primary"
            data-selector={dataSelector('cta', DATA_SELECTOR)}
          >
            {buttonLabel}
          </Cta>
        </Link>
      )}
    </Col>
  );

  return (
    <div
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      data-elbcontext="component:multi_feature_showcase"
    >
      <StyledSectionHeading
        data-selector={dataSelector('heading', DATA_SELECTOR)}
        headline={headline}
        content={content}
        alignment={
          isMobile
            ? SectionHeader.ALIGNMENT_LEFT
            : SectionHeader.ALIGNMENT_CENTER
        }
        index={index}
      />
      <Grid>
        <Row>
          {imageIsLeftAligned ? imageCol : contentCol}
          {imageIsLeftAligned ? contentCol : imageCol}
        </Row>
      </Grid>
    </div>
  );
};

MultiFeatureShowcase.IMAGE_ALIGNMENT_LEFT = ALIGNMENT.LEFT;
MultiFeatureShowcase.IMAGE_ALIGNMENT_RIGHT = ALIGNMENT.RIGHT;

MultiFeatureShowcase.propTypes = {
  headline: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonTrackingId: PropTypes.string,
  buttonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  content: richTextPropType,
  fallbackImage: imagePropType,
  contentType: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  alignment: PropTypes.oneOf([ALIGNMENT.LEFT, ALIGNMENT.RIGHT]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: imagePropType,
      imageMobile: imagePropType,
      description: richTextPropType,
      featureName: PropTypes.string,
      trackingId: PropTypes.string,
      optimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  index: PropTypes.number,
};

export default MultiFeatureShowcase;
