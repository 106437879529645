import React from 'react';
import PropTypes from 'prop-types';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import Image from '~/shared/components/Image';
import dataSelector from '~/shared/util/data-selector';

const DATA_SELECTOR = 'multi_feature_showcase';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const imageStyles = css`
  animation: ${fadeIn} 1.5s;
`;

const StyledImage = styled(Image)(imageStyles);

const imageContainerStyles = ({ theme }) => css`
  margin-bottom: 0;

  ${theme.mq.untilKilo} {
    display: none;
  }
`;

const ImageContainer = styled('figure')(imageContainerStyles);

/**
 * Image Media component for CaptionedShowcase
 */
const MultiFeatureShowcaseImage = ({ src = '', alt = '' }) => (
  <ImageContainer
    data-selector={dataSelector('image-container', DATA_SELECTOR)}
  >
    <StyledImage
      src={src}
      alt={alt}
      key={src}
      width={800}
      height={800}
      srcSet={[
        { src, size: '400w', height: 400, width: 400 },
        { src, size: '800w', height: 800, width: 800 },
      ]}
      sizes="(min-width: 600px) 40vw, 100vw"
    />
  </ImageContainer>
);

MultiFeatureShowcaseImage.propTypes = {
  /**
   * Displayed alt text
   */
  alt: PropTypes.string,
  /**
   * Displayed image source
   */
  src: PropTypes.string,
  /**
   * Name in contentful for tracking
   */
  name: PropTypes.string,
};

/**
 * @component
 */
export default MultiFeatureShowcaseImage;
